<template>
  <div data-app>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>

    <div class="tabs">
      <v-tabs
        v-model="tab"
        background-color="light primary"
        class="elevation-2"
        dark
      >
        <v-tab>Продукт</v-tab>
        <template v-if="$route.params.id">
          <v-tab>Рецепти</v-tab>
        </template>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <vue-form-generator
                ref="form"
                :schema="productSchema"
                :model="product"
                :options="formOptions"
              ></vue-form-generator>

              <v-btn
                @click="saveProduct()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <template v-if="$route.params.id">
          <v-tab-item>
            <v-btn
              @click="addFormula()"
              class="btn btn-success btn-elevate kt-login__btn-primary text-white my-4"
              >{{ $t("BUTTON.ADD_RECIPE") }}
            </v-btn>

            <v-expansion-panels accordion>
              <v-expansion-panel
                v-for="(formula, index, key) in productFormula"
                v-bind:key="key"
              >
                <v-expansion-panel-header>
                  <div class="text-left">
                    <h4>{{ formula.name }}</h4>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-tabs
                    align-with-title
                    v-model="formulaTab"
                    background-color="light primary"
                    class="elevation-2"
                    dark
                  >
                    <v-tabs-slider color="yellow"></v-tabs-slider>
                    <v-tab
                      v-for="(tabName, tabIndex) in formulaTabs"
                      :key="tabIndex"
                    >
                      {{ tabName }}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="formulaTab">
                    <v-tab-item class="pl-3 mt-3">
                      <div class="form-group required">
                        <label for="">
                          <span> Име:</span>
                        </label>
                        <div class="field-wrap">
                          <input
                            type="text"
                            v-model="formula.name"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <div
                        class="form-group required"
                        v-for="item of _.filter(formula.details, {
                          typeableType: 'product'
                        })"
                        v-bind:key="item.id"
                      >
                        <label for="">
                          <span> Суровина:</span>
                        </label>
                        <div class="field-wrap">
                          <vu-select
                            append-to-body
                            :options="rawProducts"
                            label="name"
                            v-model="item.typeableId"
                            :value="item.typeableId"
                            :reduce="rawProducts => rawProducts.id"
                          ></vu-select>
                        </div>
                      </div>

                      <div class="form-group required">
                        <label for="">
                          <span> Инструкция номер:</span>
                        </label>
                        <div class="field-wrap">
                          <input
                            type="text"
                            v-model="formula.instruction"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <v-row
                        v-for="item of _.filter(formula.details, {
                          typeableType: 'material'
                        })"
                        v-bind:key="item.id"
                      >
                        <v-col cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> Материал:</span>
                            </label>
                            <div class="field-wrap">
                              <vu-select
                                :options="materials"
                                label="name"
                                append-to-body
                                v-model="item.typeableId"
                                :value="item.typeableId"
                                :reduce="materials => materials.id"
                              >
                                <template v-slot:selected-option="option">
                                  {{ option.typeName }} {{ option.name }}
                                </template>
                                <template v-slot:option="option">
                                  {{ option.typeName }} {{ option.name }}
                                </template>
                              </vu-select>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="form-group">
                            <label for="">
                              <span> Брой:</span>
                            </label>
                            <div class="field-wrap">
                              <input
                                type="number"
                                class="form-control"
                                v-model="item.quantity"
                              />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <br />
                          <v-btn
                            @click="deleteMaterial(item, index)"
                            class="btn btn-danger btn-block btn-elevate kt-login__btn-primary text-white mt-2"
                            >{{ $t("BUTTON.DELETE") }}</v-btn
                          >
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> Добави материал:</span>
                            </label>
                            <div class="field-wrap">
                              <vu-select
                                append-to-body
                                :options="materials"
                                label="name"
                                v-model="temp.material_id"
                                :reduce="materials => materials.id"
                              >
                                <template v-slot:selected-option="option">
                                  {{ option.typeName }} {{ option.name }}
                                </template>
                                <template v-slot:option="option">
                                  {{ option.typeName }} {{ option.name }}
                                </template>
                              </vu-select>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="form-group required">
                            <label for="">
                              <span> Брой:</span>
                            </label>
                            <div class="field-wrap">
                              <input
                                type="number"
                                class="form-control"
                                v-model="temp.material_quantity"
                              />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <br />
                          <v-btn
                            @click="
                              if (
                                temp.material_id > 0 &&
                                temp.material_quantity > 0
                              ) {
                                productFormula[index].details.push({
                                  typeableId: temp.material_id,
                                  quantity: temp.material_quantity,
                                  typeableType: 'material'
                                });
                              }
                            "
                            class="btn btn-success btn-block btn-elevate kt-login__btn-primary text-white mt-2"
                            >{{ $t("BUTTON.ADD") }}</v-btn
                          >
                        </v-col>
                      </v-row>

                      <v-btn
                        @click="saveFormula('update', formula)"
                        class="btn btn-success mr-2 btn-elevate kt-login__btn-primary text-white"
                        >Запиши</v-btn
                      >

                      <v-btn
                        @click="deleteFormula(formula)"
                        class="btn btn-danger btn-elevate kt-login__btn-primary text-white"
                        >Изтрий</v-btn
                      >
                    </v-tab-item>
                    <v-tab-item>
                      <v-row
                        v-for="operation of productOperations[formula.id]"
                        v-bind:key="operation.id"
                      >
                        <v-col cols="4">
                          <div class="form-group required">
                            <label for="">
                              <span> Име:</span>
                            </label>
                            <div class="field-wrap">
                              <input
                                type="text"
                                v-model="operation.name"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="2">
                          <div class="form-group required">
                            <label for="">
                              <span> Норматив:</span>
                            </label>
                            <div class="field-wrap">
                              <input
                                type="text"
                                v-model="operation.norm"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <br />
                          <v-btn
                            @click="saveOperation('update', operation)"
                            class="btn btn-success btn-block btn-elevate kt-login__btn-primary text-white mt-2"
                            >{{ $t("BUTTON.SAVE") }}</v-btn
                          >
                        </v-col>
                        <v-col cols="3">
                          <br />
                          <v-btn
                            @click="deleteOperation(operation)"
                            class="btn btn-danger btn-block btn-elevate kt-login__btn-primary text-white mt-2"
                            >{{ $t("BUTTON.DELETE") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                      <hr />
                      <h4>Добави нова операция</h4>
                      <br />
                      <v-row>
                        <v-col cols="4">
                          <div class="form-group required">
                            <label for="">
                              <span> Име:</span>
                            </label>
                            <div class="field-wrap">
                              <input
                                type="text"
                                v-model="newOperation.name"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <div class="form-group required">
                            <label for="">
                              <span> Норматив:</span>
                            </label>
                            <div class="field-wrap">
                              <input
                                type="text"
                                v-model="newOperation.norm"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="4">
                          <br />
                          <v-btn
                            @click="
                              saveOperation('save', newOperation, formula.id)
                            "
                            class="btn btn-success btn-block btn-elevate kt-login__btn-primary text-white mt-2"
                            >{{ $t("BUTTON.ADD") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
        </template>
      </v-tabs-items>
    </div>

    <v-dialog v-model="addNew" v-if="addNew" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("BUTTON.ADD_RECIPE") }}</span>
          <div
            v-if="
              typeof error_messages === 'object' &&
                Object.keys(error_messages).length > 0
            "
            class="alert alert-danger w-100"
          >
            <ul>
              <template v-for="(error, i) in error_messages">
                <span v-bind:key="i">
                  <li v-for="(message, ii) in error" v-bind:key="ii">
                    {{ message }}
                  </li>
                </span>
              </template>
            </ul>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <div class="form-group required">
                  <label for="">
                    <span> Име:</span>
                  </label>
                  <div class="field-wrap">
                    <input
                      type="text"
                      v-model="newFormula.name"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="form-group required">
                  <label for="">
                    <span> Суровина:</span>
                  </label>
                  <div class="field-wrap">
                    <vu-select
                      append-to-body
                      :options="rawProducts"
                      label="name"
                      v-model="newFormula.details[0].typeableId"
                      :value="newFormula.details[0].typeableId"
                      :reduce="rawProducts => rawProducts.id"
                    ></vu-select>
                  </div>
                </div>

                <div class="form-group required">
                  <label for="">
                    <span> Инструкция номер:</span>
                  </label>
                  <div class="field-wrap">
                    <input
                      type="text"
                      v-model="newFormula.instruction"
                      class="form-control"
                    />
                  </div>
                </div>

                <v-row>
                  <v-col cols="6">
                    <div class="form-group">
                      <label for="">
                        <span> Материал:</span>
                      </label>
                      <div class="field-wrap">
                        <vu-select
                          append-to-body
                          :options="materials"
                          label="name"
                          v-model="newFormula.material_id"
                          :reduce="materials => materials.id"
                        >
                          <template v-slot:selected-option="option">
                            {{ option.typeName }} {{ option.name }}
                          </template>
                          <template v-slot:option="option">
                            {{ option.typeName }} {{ option.name }}
                          </template>
                        </vu-select>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="3" style="max-width: 16%; flex: 0 0 16%">
                    <div class="form-group required">
                      <label for="">
                        <span> Брой:</span>
                      </label>
                      <div class="field-wrap">
                        <input
                          type="number"
                          class="form-control"
                          v-model="newFormula.material_quantity"
                        />
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <br />
                    <v-btn
                      @click="addMaterial()"
                      class="btn btn-success btn-block btn-elevate kt-login__btn-primary text-white mt-2"
                      >{{ $t("BUTTON.ADD") }}</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row
                  v-for="item of _.filter(newFormula.details, {
                    typeableType: 'material'
                  })"
                  v-bind:key="item.id"
                >
                  <v-col cols="6" style="max-width: 65%; flex: 0 0 65%">
                    <div class="form-group">
                      <label for="">
                        <span> Материал:</span>
                      </label>
                      <div class="field-wrap">
                        <vu-select
                          append-to-body
                          :options="materials"
                          label="name"
                          v-model="item.typeableId"
                          :value="item.typeableId"
                          :reduce="materials => materials.id"
                        ></vu-select>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="3" style="max-width: 16%; flex: 0 0 16%">
                    <div class="form-group">
                      <label for="">
                        <span> Брой:</span>
                      </label>
                      <div class="field-wrap">
                        <input
                          type="number"
                          class="form-control"
                          v-model="item.quantity"
                        />
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="3" style="max-width: 9%; flex: 0 0 9%">
                    <br />
                    <v-btn
                      id="delete-recipe"
                      @click="deleteTempMaterial(item)"
                      class="btn btn-danger btn-block btn-elevate kt-login__btn-primary text-white mt-2"
                      >X</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn btn-success btn-elevate kt-login__btn-primary text-white mr-2"
            @click="saveFormula('save', newFormula)"
            >{{ $t("BUTTON.SAVE") }}</v-btn
          >
          <v-btn
            class="btn btn-danger btn-elevate kt-login__btn-primary text-white"
            @click="resetFormula()"
            >Откажи</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css"; // optional full css additions

import {
  GET_PRODUCT,
  SAVE_PRODUCT,
  UPDATE_PRODUCT
} from "@/store/products.module";

import { GET_CLIENTS } from "@/store/clients.module";

import { GET_ALL_RAW_PRODUCTS } from "@/store/rawProducts.module";

import {
  UPDATE_PRODUCTFORMULA,
  SAVE_PRODUCTFORMULA,
  DELETE_PRODUCTFORMULA
} from "@/store/productFormulas.module";

import {
  UPDATE_PRODUCTOPERATION,
  SAVE_PRODUCTOPERATION,
  DELETE_PRODUCTOPERATION
} from "@/store/productOperations.module";

import { GET_PRODUCT_MATERIALS } from "@/store/materials.module";

import _ from "lodash";

import { mapGetters, mapState } from "vuex";
// Vue.use(VueFormGenerator)

export default {
  name: "createUpdate",

  components: {
    "vue-form-generator": VueFormGenerator.component
  },
  computed: {
    ...mapGetters(["isLoadingProducts"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  data() {
    return {
      alreadyPushedMaterials: [],
      error_messages: [],
      addNew: false,
      model: "products",
      tab: 0,
      formulaTab: null,
      formulaTabs: ["Материали", "Операции"],
      productFormula: {},
      productOperations: {},
      newOperation: {
        name: null,
        norm: null
      },
      newFormula: {
        material_id: 0,
        material_quantity: 0,
        details: [],
        instruction: ""
      },
      temp: {
        material_id: 0,
        material_quantity: 0
      },
      rawProducts: [],
      clients: [],
      materials: [],
      product: {
        name: null,
        qIB: null,
        primaryQIB: null,
        primaryQuantity: null,
        priceUnder: null,
        priceOver: null
      },
      productSchema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Име",
            model: "name",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително !!!",
              textTooSmall: "Полето трябва да бъде минимум {0} знака !!!"
            })
          },
          {
            id: "qIB",
            type: "input",
            inputType: "number",
            min: 1,
            label: "Брой кутии в кашон",
            model: "qIB",
            required: true,
            validator: VueFormGenerator.validators.number.locale({
              fieldIsRequired: "Полето е задължително !!!",
              numberTooSmall:
                "Стойността на полето трябва да бъде минимум {0}!!!"
            })
          },
          {
            type: "input",
            inputType: "number",
            label: "Първични опаковки в кутия",
            model: "primaryQIB",
            min: 1,
            required: true,
            validator: VueFormGenerator.validators.number.locale({
              fieldIsRequired: "Полето е задължително !!!",
              numberTooSmall:
                "Стойността на полето трябва да бъде минимум {0}!!!"
            })
          },
          {
            type: "input",
            inputType: "number",
            label: "Брой лекарствени единици в първична опаковка",
            model: "quantityInMedicalPackage",
            min: 1,
            required: true,
            validator: VueFormGenerator.validators.number.locale({
              fieldIsRequired: "Полето е задължително !!!",
              numberTooSmall:
                "Стойността на полето трябва да бъде минимум {0}!!!"
            })
          },
          {
            id: "priceUnder",
            type: "input",
            inputType: "number",
            label: "Продажна цена до 5000 бройки",
            model: "priceUnder",
            required: true,
            validator: VueFormGenerator.validators.number.locale({
              fieldIsRequired: "Полето е задължително !!!",
              numberTooSmall:
                "Стойността на полето трябва да бъде минимум {0}!!!"
            })
          },
          {
            type: "input",
            inputType: "number",
            label: "Продажна цена над 5000 бройки",
            model: "priceOver",
            required: true,
            validator: VueFormGenerator.validators.number.locale({
              fieldIsRequired: "Полето е задължително !!!",
              numberTooSmall:
                "Стойността на полето трябва да бъде минимум {0}!!!"
            })
          }
        ]
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true
      }
    };
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Продукти", route: "/products" },
        { title: current_page }
      ]);
    }, 300);

    if (typeof this.$route.params.id !== "undefined") {
      this.getData().then(() => {
        vm.getRawProducts();
        vm.getMaterials();
        vm.getClients();
      });
    } else {
      vm.getClients();
    }
  },
  methods: {
    resetFormula: function() {
      let vm = this;
      vm.newFormula = {
        material_id: 0,
        material_quantity: 0,
        details: []
      };

      vm.addNew = false;
    },
    addFormula: function() {
      let vm = this;
      vm.addNew = true;
      vm.newFormula.productId = vm.$route.params.id;
      vm.newFormula.details.push({
        typeableId: null,
        typeableType: "product",
        quantity: 1
      });
    },
    addMaterial: function() {
      let vm = this;

      if (
        vm.newFormula.material_id != 0 &&
        vm.newFormula.material_quantity > 0
      ) {
        vm.newFormula.details.push({
          typeableId: vm.newFormula.material_id,
          quantity: vm.newFormula.material_quantity,
          typeableType: "material"
        });

        vm.newFormula.material_id = 0;
        vm.newFormula.material_quantity = 0;
      }
    },
    async saveProduct() {
      let vm = this;

      vm.error_messages = [];

      let errors = await this.$refs.form.validate();

      if (errors.length) {
        return;
      }

      var action =
        typeof this.$route.params.id !== "undefined"
          ? UPDATE_PRODUCT
          : SAVE_PRODUCT;

      vm.$store
        .dispatch(action, { payload: vm.product })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });
          vm.$router.push({ name: "productsList" });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },

    saveFormula: function(do_action, formula) {
      let vm = this;
      var api_service =
        do_action == "save" ? SAVE_PRODUCTFORMULA : UPDATE_PRODUCTFORMULA;
      this.error_messages = [];

      vm.$store
        .dispatch(api_service, { payload: formula })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$router.push({
            name: "productsCreateUpdate",
            params: { id: data.data.productId }
          });

          vm.getData();

          if (do_action == "save") {
            vm.resetFormula();
          }
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "productsList" });
          }
        });
    },
    saveOperation: _.debounce(function(do_action, item, formulaId = null) {
      let vm = this;
      var api_service =
        do_action == "save" ? SAVE_PRODUCTOPERATION : UPDATE_PRODUCTOPERATION;
      this.error_messages = [];
      if (do_action == "save") {
        item.formulaId = formulaId;
        item.productId = this.$route.params.id;
      }

      vm.$store
        .dispatch(api_service, { payload: item })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$router.push({
            name: "productsCreateUpdate",
            params: { id: data.data.productId }
          });

          vm.getData();

          if (do_action == "save") {
            vm.newOperation = { name: "", norm: "" };
          }
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "productsList" });
          }
        });
    }, 750),
    getData: function() {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;
      return this.$store
        .dispatch(GET_PRODUCT, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.product = data.data;
            vm.productFormula = data.data.formulas;

            vm.product.formulas.forEach(formula => {
              vm.productOperations[formula.id] = formula.operations;
            });
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "productsList" });
          }
        });
    },
    getRawProducts: function() {
      let vm = this;

      // let id = this.$route.params.id;
      this.error_messages = [];
      this.$store
        .dispatch(GET_ALL_RAW_PRODUCTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.rawProducts = data.data;

            vm.productFormula.forEach(formula => {
              formula.details.forEach(detail => {
                if (detail.typeableType === "product" && detail.isArchived) {
                  vm.rawProducts.push({
                    id: detail.typeableId,
                    name: detail.name + " (Архивирана)"
                  });
                }
              });
            });
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getMaterials: function() {
      let vm = this;

      let id = this.$route.params.id;
      this.error_messages = [];
      this.$store
        .dispatch(GET_PRODUCT_MATERIALS, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.materials = data.data;

            for (const material of vm.materials) {
              vm.alreadyPushedMaterials.push(material.id);
            }

            vm.productFormula.forEach(formula => {
              formula.details.forEach(detail => {
                if (
                  detail.typeableType === "material" &&
                  detail.isArchived &&
                  !vm.alreadyPushedMaterials.includes(detail.typeableId)
                ) {
                  vm.alreadyPushedMaterials.push(detail.typeableId);

                  vm.materials.push({
                    id: detail.typeableId,
                    name: detail.name + " (Архивиран)",
                    typeName: detail.typeName
                  });
                }
              });
            });
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getClients: function() {
      let vm = this;

      // let id = this.$route.params.id;
      this.error_messages = [];
      this.$store
        .dispatch(GET_CLIENTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.clients = data.data;

            if (
              typeof this.$route.params.id !== "undefined" &&
              vm.product.clientIsArchived
            ) {
              vm.clients.push({
                id: vm.product.clientId,
                name: vm.product.clientName + " (Архивиран)"
              });
            }

            let clientField = {
              type: "select",
              inputType: "text",
              label: "Клиент",
              model: "clientId",
              required: true,
              selectOptions: { noneSelectedText: "избери" },
              values: vm.clients,
              validator: VueFormGenerator.validators.required.locale({
                fieldIsRequired: "Полето е задължително !!!"
              })
            };

            let noteField = {
              type: "textArea",
              label: "Забележка",
              model: "note"
            };

            vm.productSchema.fields.push(clientField);
            vm.productSchema.fields.push(noteField);
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    deleteFormula: function(formula) {
      let vm = this;
      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "За да завършите това действие, трябва да запишете промените!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.productFormula = _.remove(vm.productFormula, function(n) {
            return n !== formula;
          });

          vm.error_messages = [];
          vm.$store
            .dispatch(DELETE_PRODUCTFORMULA, formula.id)
            .then(data => {
              vm.$notification["success"]({
                message: data.status,
                description: data.message
              });

              vm.$router.push({
                name: "productsCreateUpdate",
                params: { id: data.data.newProductId }
              });

              vm.getData();
            })
            .catch(response => {
              vm.error_messages = response.data.errors;
              vm.$scrollTo("html", 420, {
                offset: -300
              });

              if (response.status === 404) {
                vm.$notification["success"]({
                  message: response.status,
                  description: response.message
                });
                vm.$router.push({ name: "productsList" });
              }
            });
        }
      });
    },
    deleteMaterial: function(material, index) {
      let vm = this;
      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "За да завършите това действие, трябва да запишете промените!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.productFormula[index].details = _.remove(
            vm.productFormula[index].details,
            function(n) {
              return n !== material;
            }
          );
        },
        onCancel() {}
      });
    },
    deleteTempMaterial: function(material) {
      let vm = this;
      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "За да завършите това действие, трябва да запишете промените!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.newFormula.details = _.remove(vm.newFormula.details, function(n) {
            return n !== material;
          });
        },
        onCancel() {}
      });
    },
    deleteOperation: function(item) {
      let vm = this;
      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.error_messages = [];
          vm.$store
            .dispatch(DELETE_PRODUCTOPERATION, item.id)
            .then(data => {
              vm.$notification["success"]({
                message: data.status,
                description: data.message
              });

              vm.$router.push({
                name: "productsCreateUpdate",
                params: { id: data.data.newProductId }
              });

              vm.getData();
            })
            .catch(response => {
              vm.error_messages = response.data.errors;
              vm.$scrollTo("html", 420, {
                offset: -300
              });

              if (response.status === 404) {
                vm.$notification["success"]({
                  message: response.status,
                  description: response.message
                });
              }
            });
        },
        onCancel() {}
      });
    }
  }
};
</script>

<style scoped>
.my-card-fh {
  min-height: 80vh;
}

#delete-recipe.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 33px;
  padding: 0 16px;
}
</style>
